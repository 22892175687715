'use strict';

import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import { setPageTitle } from 'helpers/TitleHelper';
import { RouteContext } from 'components/RouteRoot';
import ArrowLeft from 'svgs/arrow-left.svg';
import { Button, KeyboardShortcut } from '@fsg/spokes';

import 'scss/utilities/route-header.scss';

const RouteHeader = ({ className, back, backTitle, pageTitle, history, children }) => {
  const { t } = useTranslation();
  const { containerScrollTop } = useContext(RouteContext);
  
  useEffect(() => {
    setPageTitle(pageTitle);

    return () => setPageTitle();
  }, [pageTitle]);

  if (!children) return null;

  return (
    <header className={ `route-header ${ containerScrollTop > 0 ? `shadow` : `` } ${ className ? className : `` }` }>
      <div className="route-header-global">
        {
          back && (
            <>
              <Button 
                component={ NavLink }
                className="icon-button back-button"
                title={ t(`global.navigateBackTo`, { destination: backTitle }) }
                to={ back }>
                <ArrowLeft />
              </Button>
              {
                backTitle && (
                  <KeyboardShortcut
                    shortcut="shift+alt+backspace"
                    handler={ () => history.push(back) }
                    label={ t(`global.navigateBackTo`, { destination: backTitle }) } />
                )
              }
            </>
          )
        }

        { children }
      </div>
    </header>
  );
};

export default withRouter(RouteHeader);
