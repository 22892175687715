'use strict';

import React from 'react';

import 'scss/utilities/unauthenticated-page-wrapper.scss';

const UnauthenticatedPageWrapper = ({ className, children }) => (
  <div className={ `unauthenticated-page-wrapper ${ className ? className : `` }` }>
    <div className="backdrop" />
    <div className="unauthenticated-page-inner">
      { children }
    </div>
  </div>
);

export default UnauthenticatedPageWrapper;
