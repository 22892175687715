'use strict';

export const listReportsSelector = (state) => {
  return Object.keys(state.reports.reports)
    .filter((id) => !!state.reports.reports[id])
    .map((id) => ({
      id,
      ...state.reports.reports[id]
    }));
};

export const getReportSelector = (id) => (state) => {
  return state.reports.reports[id];
};
