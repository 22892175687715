'use strict';

import { 
  SET_AUTH_STATE, 
  RESET_AUTH_STATE
} from 'actions';

const initialState = {
  authenticated: false,
  access_token: ``,
  refresh_token: ``,
  expires_at: ``,
  refreshing_now: ``,
  no_refresh: ``,
};

export default function auth(state = initialState, action) {
  switch(action.type) {
    case SET_AUTH_STATE:
      return {
        ...state,
        ...action.payload
      };
    case RESET_AUTH_STATE: 
      return { 
        ...initialState 
      };
    default:
      return state;
  }
}
