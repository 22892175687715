'use strict';

import React from 'react';
import { useSelector } from 'react-redux';

import App from 'components/App';
import { ThemeProvider } from '@fsg/spokes';

const ThemeRoot = () => {
  const themeSetting = useSelector((state) => state.settings.global_settings.theme);

  let theme = `chariot-light`;

  if (themeSetting == `dark`) theme = `chariot-dark`;
  if (themeSetting == `system` && window.matchMedia && window.matchMedia(`(prefers-color-scheme: dark)`).matches) theme = `chariot-dark`;

  return (
    <ThemeProvider theme={ theme }>
      <App />
    </ThemeProvider>
  );
};

export default ThemeRoot;
