'use strict';

import React from 'react';
import ThemeRoot from 'components/ThemeRoot';

import { ChariotProvider } from '@fsg/chariot.js/adaptors/react';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { PersistGate } from 'redux-persist/integration/react';

import * as atatus from 'atatus-spa';

import store, { persistor } from 'helpers/ReduxStore.js';
import i18n from 'helpers/i18n.js';

import LoadingRoute from 'components/utilities/LoadingRoute';

if (process.env.ATATUS_KEY) atatus.config(process.env.ATATUS_KEY).install();

const Root = () => {
  return (
    <ChariotProvider domain={ process.env.CHARIOT_DOMAIN } cache cors>
      <I18nextProvider i18n={ i18n }>
        <Provider store={ store }>
          <BrowserRouter>
            <PersistGate
              loading={ <LoadingRoute /> }
              persistor={ persistor }>
              <ThemeRoot />
            </PersistGate>
          </BrowserRouter>
        </Provider>
      </I18nextProvider>
    </ChariotProvider>
  );
};

export default Root;
