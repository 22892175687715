'use strict';

import React, { useContext } from 'react';
import { RouteContext } from 'components/RouteRoot';

import 'scss/utilities/route-container.scss';

const RouteContainer = ({ className, children }) => {
  const { setContainerScrollTop } = useContext(RouteContext);

  const handleScroll = (e) => setContainerScrollTop(e.target.scrollTop);
  
  return (
    <div onScroll={ handleScroll } className={ `route-container ${ className ? className : `` }` }>
      { children }
    </div>
  );
};

export default RouteContainer;
