'use strict';

import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateGlobalSetting } from 'actions/settings';
import { ChariotContext } from '@fsg/chariot.js/adaptors/react';
import { ToggleInput } from '@fsg/spokes';
import Moon from 'svgs/moon';
import Sun from 'svgs/sun';

import 'scss/utilities/theme-toggle.scss';

const ThemeToggle = () => {
  const dispatch = useDispatch(),
    chariot = useContext(ChariotContext);

  const theme = useSelector((state) => state.settings.global_settings.theme);
  const setTheme = (theme) => dispatch(updateGlobalSetting(chariot, `theme`, theme));

  let value = true;

  if (theme == `dark`) value = false;
  if (theme == `system` && window.matchMedia && window.matchMedia(`(prefers-color-scheme: dark)`).matches) value = false;

  const handleSetValue = (value) => value ? setTheme(`light`) : setTheme(`dark`);

  return (
    <div className={ `theme-toggle ${ value ? `light` : `dark` }-selected` }>
      <Moon className="moon" width="2.5rem" height="2.5rem" onClick={ () => setTheme(`dark`) } />
      <ToggleInput
        value={ value }
        onChange={ (e) => handleSetValue(e.target.checked) } />
      <Sun className="sun" width="2.5rem" height="2.5rem" onClick={ () => setTheme(`light`) } />
    </div>
  );
};

export default ThemeToggle;
