'use strict';

import React from 'react';
import AppNav from 'components/utilities/AppNav';
import AddPointsToReportModal from 'components/reports/AddPointsToReportModal';

import 'scss/utilities/authenticated-page-wrapper.scss';

const AuthenticatedPageWrapper = ({ children, hideNav }) => {
  return (
    <div className="authenticated-page-wrapper">
      { !hideNav && <AppNav /> }
      { children }
      <AddPointsToReportModal />
    </div>
  );
};

export default AuthenticatedPageWrapper;
