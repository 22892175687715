'use strict';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from 'translations/en.json';
import blank from 'translations/blank.json';

i18n
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV == `development`,
    resources: {
      en: { translation: en },
      blank: { translation: blank }
    },
    fallbackLng: `en`,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
