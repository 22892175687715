'use strict';

import React, { useState, createContext } from 'react';

import 'scss/utilities/route-root.scss';

export const RouteContext = createContext({
  containerScrollTop: 0,
  setContainerScrollTop: () => null
});

const RouteRoot = ({ className, children }) => {
  const [containerScrollTop, setContainerScrollTop] = useState(0);

  return (
    <RouteContext.Provider value={ { containerScrollTop, setContainerScrollTop } }>
      <div className={ `route-root ${ className ? className : `` }` }>
        { children }
      </div>
    </RouteContext.Provider>
  );
};

export default RouteRoot;
