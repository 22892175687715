'use strict';

/* Auth */
export const RESET_AUTH_STATE = `AUTH/RESET_STATE`;
export const SET_AUTH_STATE = `AUTH/SET_STATE`;

/* Settings */
export const REHYDRATE_CLIENT_SETTINGS = `SETTINGS/REHYDRATE_CLIENT`;
export const UPDATE_CLIENT_SETTING = `SETTINGS/UPDATE_CLIENT_SETTING`;
export const UPDATE_CLIENT_SETTINGS = `SETTINGS/UPDATE_CLIENT_SETTINGS`;
export const REHYDRATE_GLOBAL_SETTINGS = `SETTINGS/REHYDRATE_GLOBAL`;
export const UPDATE_GLOBAL_SETTING = `SETTINGS/UPDATE_GLOBAL_SETTING`;

/* Reports */
export const CREATE_REPORT = `REPORTS/CREATE_REPORT`;
export const UPDATE_REPORT = `REPORTS/UPDATE_REPORT`;
export const DELETE_REPORT = `REPORTS/DELETE_REPORT`;
export const QUEUE_CHARIOT_EQUIPMENT = `REPORTS/QUEUE_CHARIOT_EQUIPMENT`;
export const RESET_ADD_MODAL = `REPORTS/RESET_ADD_MODAL`;
export const SELECT_POINT_ID = `REPORTS/SELECT_POINT_ID`;
export const DESELECT_POINT_ID = `REPORTS/DESELECT_POINT_ID`;
export const ADD_CHART_TO_REPORT = `REPORTS/ADD_CHART_TO_REPORT`;
export const UPDATE_REPORT_CHART = `REPORTS/UPDATE_REPORT_CHART`;
