'use strict';

import { 
  CREATE_REPORT,
  UPDATE_REPORT,
  DELETE_REPORT,
  ADD_CHART_TO_REPORT,
  UPDATE_REPORT_CHART,
  QUEUE_CHARIOT_EQUIPMENT,
  RESET_ADD_MODAL,
  SELECT_POINT_ID,
  DESELECT_POINT_ID
} from 'actions';

/*
 
report model: 

[report_id]: {
  name: string

  charts: {
    [chart_id]: {
      sort_order: number
      start_date: date
      end_date: date
      resolution: resolution
      stack_bars: boolean
      bar_size: 1, 2, 3, 4, or 5
      data[]: {
        color: string
        type: weather or point
        dashed: boolean
        start_date: date
        yAxis: boolean
        xAxis: boolean
        weather_key: temperature or humidity
        site_id: string
        point_id: string
        resolution: resolution
        point_chart_type: line, gradientPolygon, bar, or heatmap
        point_value_position: {
          type: equal_to, more_than, or less_than
          compare: string
        }
      }
    }
  }
}

 */

export const DEFAULT_REPORT_CHART = {
  range_preset: `PAST_DAY`,
  start_date: new Date(Date.now() - (86400000 * 3)),
  end_date: new Date(),
  resolution: `15M`,
  stack_bars: false,
  bar_count: 12,
  tile_count: 12,
  data: []
};

const initialState = {
  reports: {},

  addPointsModalOpen: false,
  selectedPointIds: [],
  availablePointIds: []
};

export default function auth(state = initialState, action) {
  switch(action.type) {
    case UPDATE_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.id]: action.data
        }
      };
    case CREATE_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.id]: {
            name: action.name,
            ...action.data
          }
        }
      };
    case ADD_CHART_TO_REPORT: {
      const { id, report_id } = action;
      const report = state.reports[report_id];
      if (!report) throw new Error(`Report not found`);
      const sort_order = Object.values(report.charts || {}).filter((c) => !!c).length;
      const data = { ...report };
      if (!data.charts) data.charts = {};
      data.charts[id] = {
        ...DEFAULT_REPORT_CHART,
        sort_order
      };
      return {
        ...state,
        reports: {
          ...state.reports,
          [report_id]: data
        }
      };
    }
    case UPDATE_REPORT_CHART: {
      const { id, report_id, data: chartData } = action;
      const report = state.reports[report_id];
      if (!report) throw new Error(`Report not found`);
      const data = { ...report };
      if (!data.charts) data.charts = {};
      if (!data.charts[id]) throw new Error(`Chart not found`);
      data.charts[id] = {
        ...data.charts[id],
        ...chartData
      };
      return {
        ...state,
        reports: {
          ...state.reports,
          [report_id]: data
        }
      };
    }
    case DELETE_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.id]: null
        }
      };
    case QUEUE_CHARIOT_EQUIPMENT:
      return {
        ...state,
        addPointsModalOpen: true,
        selectedPointIds: action.selected,
        availablePointIds: action.available
      };
    case RESET_ADD_MODAL:
      return {
        ...state,
        addPointsModalOpen: false,
        selectedPointIds: [],
        availablePointIds: []
      };
    case SELECT_POINT_ID:{
      const selectedPointIds = [...state.selectedPointIds]
        .filter((point_id) => point_id !== action.point_id)
        .concat(action.point_id);

      return {
        ...state,
        selectedPointIds
      };
    } 
    case DESELECT_POINT_ID:{
      const selectedPointIds = [...state.selectedPointIds]
        .filter((point_id) => point_id !== action.point_id);

      return {
        ...state,
        selectedPointIds
      };
    } 
    default:
      return state;
  }
}
