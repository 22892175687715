'use strict';

const defaultPageTitle = document.title;

export const setPageTitle = (title = ``) => {
  title = `${ title }`.trim();

  if (title) {
    document.title = `${ title } « ${ defaultPageTitle }`;
  } else {
    document.title = defaultPageTitle;
  }
};
