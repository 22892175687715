'use strict';

import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthenticatedPageWrapper from 'components/AuthenticatedPageWrapper';
import UnauthenticatedPageWrapper from 'components/UnauthenticatedPageWrapper';
import LoadingRoute from 'components/utilities/LoadingRoute';
import LoadingUnauthenticatedRoute from 'components/utilities/LoadingUnauthenticatedRoute';
import ErrorCatcher from 'components/utilities/ErrorCatcher';
import { getQueryParam, stringifyParams } from 'helpers/QueryHelper';
import { setAuthState } from 'actions/auth';
import { rehydrateClientSettings, rehydrateGlobalSettings } from 'actions/settings';
import { ThemeLanguage, useAppNotification } from '@fsg/spokes';
import { useChariot } from '@fsg/chariot.js/adaptors/react/hooks';
import i18n from 'helpers/i18n.js';
import * as atatus from 'atatus-spa';

import 'scss/app.scss';

const App = () => {
  const dispatch = useDispatch();
  const chariot = useChariot();
  const notify = useAppNotification();
  const auth = useSelector((state) => state.auth);
  const [rehydrated, setRehydrated] = useState(false);
  const [lng, setLng] = useState(i18n.language);

  useEffect(() => {
    (async () => {
      chariot.onLoginStateChanged.subscribe((loginState) => {
        if (loginState && loginState.user) {
          const user = loginState.user;

          if (process.env.ATATUS_KEY) {
            atatus.setUser(user.id, user.email, user.name);
          }

          if (window.hj) {
            window.hj(`identify`, user.id, {
              email: user.email,
              name: user.name
            });
          }

          setRehydrated(true);
        }
      });
  
      chariot.auth.onAuthUpdated.subscribe((authState) => {
        dispatch(setAuthState(authState));
      });
  
      chariot.onGlobalSettingsChanged.subscribe((globalSettings) => {
        dispatch(rehydrateGlobalSettings(globalSettings));
      });
  
      chariot.onClientSettingsChanged.subscribe((clientSettings) => {
        dispatch(rehydrateClientSettings(clientSettings));
      });

      chariot.logger.onLog.subscribe(({ level, message }) => {
        const type = level == `error` ? `error` : `warning`;

        if (type == `error` && process.env.ATATUS_KEY) {
          atatus.notify(new Error(message));
        }

        notify({
          type,
          message
        });
      });

      i18n.on(`languageChanged`, (lng) => setLng(lng));

      const newAuthState = { ...auth };
      newAuthState.refreshing_now = false;

      chariot.auth.setState(newAuthState);

      await chariot.waitForLogin();
      await chariot.getUserSettings();
    })();
  }, []);

  const redirectPath = getQueryParam(`r`),
    redirectParam = stringifyParams({
      r: `${ window.location.pathname }${ window.location.search }`
    });

  return (
    <ErrorCatcher>
      <ThemeLanguage lng={ lng } />
      <main>
        {
          /* the order of the Suspense and PageWrappers are intentionally different */
          !auth.authenticated ? (
            <Suspense fallback={ <LoadingUnauthenticatedRoute /> }>
              <UnauthenticatedPageWrapper key="unauthenticated-page-wrapper">
                <Switch>
                  <Route path="/(login|reset)" component={ lazy(() => import(`components/login/LoginRouter.jsx`)) } />
                  <Redirect from="*" to={ `/login${ redirectParam }` } />
                </Switch>
              </UnauthenticatedPageWrapper>
            </Suspense>
          ) : rehydrated ? (
            <AuthenticatedPageWrapper key="authenticated-page-wrapper">
              <ErrorCatcher>
                <Suspense fallback={ <LoadingRoute /> }>
                  <Switch>
                    <Route path="/sites" component={ lazy(() => import(`components/sites/SitesRouter.jsx`)) } />
                    <Route path="/site-groups" component={ lazy(() => import(`components/siteGroups/SiteGroupsRouter.jsx`)) } />
                    <Route path="/rules" component={ lazy(() => import(`components/rules/RulesRouter.jsx`)) } />
                    <Route path="/reports" component={ lazy(() => import(`components/reports/ReportsRouter.jsx`)) } />
                    <Route path="/users" component={ lazy(() => import(`components/users/UsersRouter.jsx`)) } />
                    {
                      redirectPath && (
                        <Redirect from="*" to={ redirectPath } />
                      )
                    }
                    <Redirect exact from="/login" to="/sites" />
                    <Redirect exact from="/" to="/sites" />
                    <Route path="*" component={ lazy(() => import(`components/PageNotFound.jsx`)) } />
                  </Switch>
                </Suspense>
              </ErrorCatcher>
            </AuthenticatedPageWrapper>
          ) : (
            <LoadingUnauthenticatedRoute />
          )
        }
      </main>
    </ErrorCatcher>
  );
};

export default App;
