'use strict';

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useChariot } from '@fsg/chariot.js/adaptors/react/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  Button,
  SelectInput,
  TextInput,
  ToggleInput,
  SortableTable,
  useAppNotification
} from '@fsg/spokes';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_REPORT_CHART } from 'reducers/reports';
import { resetAddPointsModal, selectPointId, deselectPointId, updateReport, createReport } from 'actions/reports';
import { listReportsSelector, getReportSelector } from 'helpers/ReportStore';

import 'scss/utilities/add-points-to-report-modal.scss';

const colorPool = [
  `#4F53E6`,
  `#48696F`,
  `#6FA09D`,
  `#CEB908`,
  `#BCCFA1`,
  `#94BB5E`,
  `#888BE1`,
  `#DA835A`
];

const AddPointsToReportModal = ({ history }) => {
  const dispatch = useDispatch();
  const chariot = useChariot();
  const { t } = useTranslation();

  const notify = useAppNotification();

  const modalOpen = useSelector((state) => state.reports.addPointsModalOpen);
  const selectedPointIds = useSelector((state) => state.reports.selectedPointIds);
  const availablePointIds = useSelector((state) => state.reports.availablePointIds);

  const [selectedReportId, setSelectedReportId] = useState();
  const [newReportName, setNewReportName] = useState(``);
  const [points, setPoints] = useState([]);
  const [loading, setLoading] = useState(false);

  const reports = useSelector(listReportsSelector);
  const selectedReport = useSelector(getReportSelector(selectedReportId));

  useEffect(() => {
    let mounted = true;

    (async () => {
      setLoading(true);
      const points = await Promise.all(availablePointIds.map((pointId) => chariot.Point.get(pointId)));
      if (!mounted) return;
      setPoints(points);
      setLoading(false);
    })();

    return () => mounted = false;
  }, [JSON.stringify(availablePointIds)]);

  useEffect(() => {
    if (!modalOpen) {
      setSelectedReportId(undefined);
      setNewReportName(``);
    }
  }, [modalOpen]);

  if (!modalOpen) return null;

  const handleAdd = () => {
    try {
      const chartId = uuidv4(),
        chartData = selectedPointIds.map((point_id, index) => ({
          color: colorPool[index] || `#000000`,
          type: `point`,
          dashed: false,
          tooltips: true,
          xAxis: index == 0,
          yAxis: index == 0,
          point_id,
          point_chart_type: `line`,
          point_value_condition: {
            type: `equal_to`,
            compare: `On`
          },
          value_lower_bound: 60,
          value_upper_bound: 84,
          value_lower_bound_color: `#EF4552`
        }));

      if (selectedReportId == `new`) {
        const createAction = createReport(newReportName, {
          charts: {
            [chartId]: {
              ...DEFAULT_REPORT_CHART,
              data: chartData
            }
          }
        });

        dispatch(createAction);

        history.push(`/reports/${ createAction.id }/charts/${ chartId }`);
      } else {
        dispatch(updateReport(selectedReportId, {
          ...selectedReport,
          charts: {
            ...selectedReport.charts,
            [chartId]: {
              ...DEFAULT_REPORT_CHART,
              data: chartData
            }
          }
        }));

        history.push(`/reports/${ selectedReportId }/charts/${ chartId }`);
      }

      dispatch(resetAddPointsModal());
    } catch(e) {
      notify({
        type: `error`,
        message: e.message
      });
    }
  };

  return (
    <Modal className="add-points-to-report-modal">
      <ModalHeader closeButtonProps={ { onClick: () => dispatch(resetAddPointsModal()) } }>
        { t(`reports.addPointsModal.header`) }
      </ModalHeader>
      <ModalBody>
        <h3>{ t(`reports.addPointsModal.selectReport`) }</h3>
        <SelectInput
          value={ selectedReportId }
          onChange={ (e) => setSelectedReportId(e.target.value) }>
          {
            reports.map(({ id, name }) => (
              <option key={ id } value={ id }>{ name }</option>
            ))
          }
          <option value="new">{ t(`reports.addPointsModal.newReport`) }</option>
        </SelectInput>
        {
          selectedReportId == `new` && 
          <TextInput
            className="new-report-name"
            placeholder={ t(`reports.addPointsModal.newReportNamePlaceholder`) }
            value={ newReportName }
            onChange={ (e) => setNewReportName(e.target.value) } />
        }
        <h3>Select Points</h3>
        <SortableTable
          loading={ loading }
          loadingRows={ loading ? [1,2,3,4,5,6,7,8,9,10] : null }
          data={ points }
          defaultSort="-suggested"
          columns={ [
            {
              key: `selected`,
              label: ``,
              display: (point) => (
                <ToggleInput
                  className="smol"
                  value={ selectedPointIds.includes(point._.id) }
                  onChange={ (e) => {
                    if (e.target.checked) {
                      dispatch(selectPointId(point._.id));
                    } else {
                      dispatch(deselectPointId(point._.id));
                    }
                  } } />
              ),
              sort: (point) => selectedPointIds.includes(point._.id) ? 1 : 0
            },
            {
              key: `name`,
              label: t(`reports.addPointsModal.pointNameLabel`),
              display: (point) => point.getDisplayName()
            },
            {
              key: `suggested`,
              label: t(`reports.addPointsModal.pointSuggestedLabel`),
              display: (point) => point._.trend ? `Yes` : ``,
              sort: (point) => point._.trend ? 1 : 0
            }
          ] } />
      </ModalBody>
      <ModalActions>
        <Button
          className="primary"
          disabled={ !selectedReportId || (selectedReportId == `new` && newReportName == ``) }
          onClick={ handleAdd }>
          { t(`reports.addPointsModal.addPoints`) }
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default withRouter(AddPointsToReportModal);
