'use strict';

import React from 'react';
import UnauthenticatedPageWrapper from 'components/UnauthenticatedPageWrapper';
import { LoadingSpinner } from '@fsg/spokes';

import 'scss/utilities/loading-unauthenticated-route.scss';

const LoadingRoute = () => {
  return (
    <UnauthenticatedPageWrapper className="loading-unauthenticated-route">
      <LoadingSpinner />
    </UnauthenticatedPageWrapper>
  );
};

export default LoadingRoute;
