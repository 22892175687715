'use strict';

import { 
  SET_AUTH_STATE,
  RESET_AUTH_STATE
} from 'actions';

export const setAuthState = (authState) => ({
  type: SET_AUTH_STATE,
  payload: authState
});

export const resetAuthState = () => ({
  type: RESET_AUTH_STATE
});
