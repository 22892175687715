'use strict';

import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, NavLink, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { resetAuthState } from 'actions/auth';
import ThemeToggle from 'components/utilities/ThemeToggle';
import { KeyboardShortcut, KeyboardKey, QuickAccess, QuickAction } from '@fsg/spokes';

import ChariotIcon from 'svgs/ChariotIcon';
import SitesIcon from 'svgs/Sites';
import RulesIcon from 'svgs/Rules';
import TrendsIcon from 'svgs/Trends';
import QuickAccessIcon from 'svgs/search';
import UsersIcon from 'svgs/Users';
import LogoutIcon from 'svgs/Logout';

import 'scss/utilities/app-nav.scss';

const AppNav = ({ location, history }) => {
  const dispatch = useDispatch(),
    { t } = useTranslation();

  const quickAccess = useRef();

  const macUser = navigator.userAgent.indexOf(`Mac OS X`) != -1 || navigator.userAgent.indexOf(`macOS`) != -1;

  const NAVIGATION = [
    {
      key: `sites`,
      label: t(`navigation.sites`),
      description: t(`navigation.sitesDesc`),
      path: `/sites`,
      shortcut: `shift+alt+s`,
      icon: SitesIcon
    },
    {
      key: `rules`,
      label: t(`navigation.rules`),
      description: t(`navigation.rulesDesc`),
      path: `/rules`,
      shortcut: `shift+alt+r`,
      icon: RulesIcon
    },
    {
      key: `reports`,
      label: t(`navigation.reports`),
      description: t(`navigation.reportsDesc`),
      path: `/reports`,
      shortcut: `shift+alt+t`,
      icon: TrendsIcon
    }
  ];

  const logout = useCallback(
    () => dispatch(resetAuthState()),
    [dispatch]
  );

  return (
    <aside className="app-nav">
      <nav>
        <Link className="logo" to="/">
          <span className="icon-wrapper"><ChariotIcon width="30" /></span>
          <span className="label">{ t(`global.chariot`) }</span>
          <span className="badge">{ t(`global.preview`) }</span>
        </Link>

        <ul>
          {
            NAVIGATION.map(({ key, label, path, icon: Icon, description, shortcut }) => (
              <li key={ key }>
                <NavLink 
                  location={ location }
                  to={ path }
                  activeClassName="active">
                  { Icon && (<span className="icon-wrapper"><Icon /></span>) }
                  <span className="label">{ label }</span>
                </NavLink>
                <KeyboardShortcut 
                  context="global"
                  label={ label }
                  description={ description }
                  shortcut={ shortcut }
                  handler={ () => history.push(path) } />
              </li>
            ))
          }
        </ul>

        <ul className="util">
          <li>
            <a 
              onClick={ () => quickAccess?.current?.toggleOpen() }>
              <span className="icon-wrapper"><QuickAccessIcon /></span>
              <span className="label">
                <div className="keyboard-shortcut">
                  <KeyboardKey kkey={ macUser ? `cmd` : `ctrl` } />
                  <KeyboardKey kkey="k" />
                </div>
              </span>
            </a>
          </li>
          <li>
            <NavLink
              location={ location }
              to="/users"
              activeClassName="active">
              <span className="icon-wrapper"><UsersIcon /></span>
              <span className="label">{ t(`navigation.users`) }</span>
            </NavLink>
            <KeyboardShortcut 
              context="global"
              label={ t(`navigation.users`) }
              description={ t(`navigation.usersDesc`) }
              shortcut="shift+alt+u" 
              handler={ () => history.push(`/users`) } />
          </li>
          <li>
            <a onClick={ logout }>
              <span className="icon-wrapper"><LogoutIcon /></span>
              <span className="label">{ t(`navigation.logout`) }</span>
            </a>
          </li>
        </ul>
        
        <ThemeToggle />
      </nav>

      <QuickAction 
        context="global"
        label={ t(`navigation.siteGroups`) }
        description={ t(`navigation.siteGroupsDesc`) }
        handler={ () => history.push(`/site-groups`) } />

      <QuickAccess ref={ quickAccess } />
    </aside>
  );
};

export default withRouter(AppNav);
