'use strict';

export const parseParams = (queryParams = ``) => {
  if (queryParams.indexOf(`?`) !== 0) return { };

  return queryParams
    .split(`?`)[1]
    .split(`&`)
    .reduce((a, q) => {
      a[q.split(`=`)[0]] = decodeURIComponent(q.split(`=`)[1]);
      return a;
    }, { });
};

export const stringifyParams = (params = { }) => {
  if (Object.keys(params).length == 0) return ``;

  let query = `?${
    Object.keys(params)
      .filter((o) => params[o] !== undefined && params[o] !== null)
      .map((o) => `${ o }=${ encodeURIComponent(params[o]) }`)
      .join(`&`)
  }`;

  if (query.length < 2) return ``;
  return query;
};

export const getQueryParam = (key) => {
  let params = parseParams(window.location.search);
  if (key in params) return params[key];
  return null;
};

export const updateQueryParamString = (obj) => {
  let params = parseParams(window.location.search);
  
  params = { 
    ...params,
    ...obj
  };

  window.history.replaceState(null, document.title, `${ window.location.pathname }${ stringifyParams(params) }`);
};
