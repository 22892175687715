'use strict';

import React from 'react';
import ReactDOM from 'react-dom';

import Root from 'components/Root';

document.documentElement.style.setProperty(`--vh`, `${ window.innerHeight * 0.01 }px`);

window.addEventListener(`resize`, () => {
  document.documentElement.style.setProperty(`--vh`, `${ window.innerHeight * 0.01 }px`);
});

ReactDOM.render(<Root />, document.getElementById(`app`));
