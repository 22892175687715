'use strict';

import React from 'react';
import { useTranslation } from 'react-i18next';
import RouteRoot from 'components/RouteRoot';
import RouteHeader from 'components/RouteHeader';
import RouteContainer from 'components/RouteContainer';
import { LoadingSpinner } from '@fsg/spokes';

import 'scss/utilities/loading-route.scss';

const LoadingRoute = () => {
  const { t } = useTranslation();

  return (
    <RouteRoot className="loading-route">
      <RouteHeader pageTitle={ t(`global.loading`) } />
      <RouteContainer className="loading">
        <LoadingSpinner />
      </RouteContainer>
    </RouteRoot>
  );
};

export default LoadingRoute;
