'use strict';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { AppError, AppMessage } from '@fsg/spokes';
import * as atatus from 'atatus-spa';

import 'scss/utilities/error-catcher.scss';

class ErrorCatcher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null
    };
  }

  componentDidCatch(error) {
    if (process.env.ATATUS_KEY) atatus.notify(error);
    console.error(error);
    this.setState({ error });
  }

  render() {
    const { t } = this.props;
    const { error } = this.state;
    if (!error) return this.props.children;

    let renderedError = error;
    
    if (error.message) renderedError = error.message;

    return (
      <div className="error-catcher">
        <AppMessage>
          { t(`global.genericErrorDescription`) }
        </AppMessage>
        <AppError>
          { renderedError }
        </AppError>
      </div>
    );
  }
}

export default withTranslation()(ErrorCatcher);
