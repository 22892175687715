'use strict';

import { 
  CREATE_REPORT,
  UPDATE_REPORT,
  DELETE_REPORT,
  ADD_CHART_TO_REPORT,
  UPDATE_REPORT_CHART,
  QUEUE_CHARIOT_EQUIPMENT,
  RESET_ADD_MODAL,
  SELECT_POINT_ID,
  DESELECT_POINT_ID
} from 'actions';
import { v4 as uuidv4 } from 'uuid';

import { Equipment } from '@fsg/chariot.js/resources/models';

export const createReport = (name, data) => {
  return {
    type: CREATE_REPORT,
    id: uuidv4(),
    name,
    data
  };
};

export const updateReport = (id, data) => {
  return {
    type: UPDATE_REPORT,
    id,
    data
  };
};

export const addChartToReport = (report_id) => {
  return {
    type: ADD_CHART_TO_REPORT,
    id: uuidv4(),
    report_id
  };
};

export const updateReportChart = (report_id, id, data) => {
  return {
    type: UPDATE_REPORT_CHART,
    id,
    report_id,
    data
  };
};

export const deleteReport = (id) => {
  return {
    type: DELETE_REPORT,
    id
  };
};

export const queueEquipment = (equipment) => {
  if (!(equipment instanceof Equipment)) throw new Error(`Invalid data provided to queueEquipment`);

  const available = [ ...equipment.points ],
    selected = available.filter(({ _ }) => _.trend);

  return {
    type: QUEUE_CHARIOT_EQUIPMENT,
    available: available.map(({ _ }) => _.id),
    selected: selected.map(({ _ }) => _.id)
  };
};

export const resetAddPointsModal = () => ({
  type: RESET_ADD_MODAL
});

export const selectPointId = (point_id) => ({
  type: SELECT_POINT_ID,
  point_id
});

export const deselectPointId = (point_id) => ({
  type: DESELECT_POINT_ID,
  point_id
});
